@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Roboto:wght@300;400;500;700&display=swap"); */

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap"); */

* {
	margin: 0;
	padding: 0;
}
html,
body {
	width: 100%;
	height: 100%;
}
.app {
	width: 100%;
}

.about {
  height: 98vh;
  display: flex;
  align-items: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}
.about__text {
  flex: 0.4 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  padding: 20px;
  border: 1px solid rgb(228, 228, 228);

  background-color: #fff;
  transform: translate(-80px);
  max-width: 300px;
  min-width: 250px;
  border-radius: 10px;
}

.about__textTop {
  display: flex;
  height: 45px;
  padding: 10px;
}
.about__textTop > h1 {
  padding: 0 10px;
  color: rgb(210, 84, 89);
  font-size: 2rem;
}
.about__textTop > h1 > span {
  color: #4c4c4c;
  font-weight: 300 !important;
}
.about__text > p {
  padding: 10px;
  color: gray;
  line-height: 1.5;
  letter-spacing: 1px;
}

.about__images {
  flex: 0.6 1;
  display: flex;
  background-color: rgb(240, 240, 240);
  padding: 20px;
  margin-bottom: 50px;
  margin-left: 100px;
  border-radius: 10px;
  border: 1px solid rgb(228, 228, 228);
}

.about__imagesLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about__imagesLeft > img {
  margin: 10px;
}
.about__imagesRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

@media screen and (min-width: 1669px) {
  .about__text {
    transform: translate(-220px);
  }
}

@media screen and (max-width: 1350px) {
  .about {
    width: 90%;
  }
}
@media screen and (max-width: 1165px) {
  .about {
    width: 98%;
  }
}
@media screen and (max-width: 1072px) {
  .about__textTop > h1 {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 1040px) {
  .about__images {
    margin-left: 50px;
  }
}
@media screen and (max-width: 989px) {
  .about {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    height: calc(90vh + 100px);
  }

  .about__images {
    transform: translate3d(0, 0, 0) !important;
    flex: 0.8 1;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
  }
  .about__text {
    max-width: 90%;
    transform: translate(0px);
    flex: 0.2 1;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 700px) {
  .about__images {
    width: 100%;
    min-width: 280px;
    order: 2;
    margin-top: 0;
    margin-bottom: 100px;
  }
  .about__text {
    margin-top: 100px;
    margin-bottom: 30px;
  }
  .about__imagesRight {
    padding: 0px;
    margin-left: 8px;
  }
  .about__imagesLeft > .image {
    width: 100%;
    min-width: 120px;
  }
  .about__imagesRight > .image {
    width: 100%;
    min-width: 120px;
  }
}

@media screen and (max-width: 400px) {
  .about {
    padding: 5px;
  }
  .about__text {
    padding: 5px;
  }
  .about__textTop > h1 {
    font-size: 1.7rem;
  }
  .about__text > p {
    font-size: 0.9rem;
  }
  .about__images {
    padding: 10px;
    border: 1px solid rgb(228, 228, 228);
  }
}

.contactUs {
  background: url(/static/media/manila.b0eb2917.jpg);
  height: 100vh;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: fill; */
  /* background-attachment: local; */

  position: relative;

  display: flex;
}

.contactUs::before {
  content: "";

  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background-color: rgb(36, 35, 35);
  opacity: 0.4;
}

.contactUs__left {
  background-color: white;
  z-index: 2;
  position: relative;
  flex: 0.5 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.contactUs__leftText {
  padding: 20px;
}

.contactUs__leftText > h1 {
  color: rgb(210, 84, 89);
  z-index: 2;
}

.contactUs__leftText > h1 > span {
  color: #4c4c4c;
  font-weight: 300 !important;
}
.contactUs__leftText > p {
  font-size: 1.2rem;
  font-weight: 300;
}

.contactUs__right {
  flex: 0.5 1;
  z-index: 2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactUs__rightMap {
  margin: 50px 0;
  width: 55%;
  min-width: 300px;
}
.contactUs__rightMap > iframe {
  border-radius: 5px;
  width: 100%;
  height: 250px;
  min-width: 300px;
}

.contactUs__rightLocation {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  min-width: 300px;
}

.contactUs__rightLocation > h1 {
  margin-bottom: 10px;
  color: rgb(210, 84, 89);
  z-index: 2;
}

.contactUs__rightLocation > h1 > span {
  color: #4c4c4c;
  font-weight: 300 !important;
}

.contactUs__rightLocation > p {
  line-height: 0.4;
}
.contactUs__rightLocation > a > .MuiSvgIcon-root {
  color: black;
}

@media screen and (max-width: 800px) {
  .contactUs {
    flex-direction: column;
    height: auto;
  }
  .contactUs__left {
    order: 2;
  }
  .contactUs__right {
    height: 100vh;
    padding: 50px;
  }
  .contactUs__rightLocation {
    min-width: 400px;
  }
  .contactUs__rightMap {
    order: 2;
    min-width: 400px;
  }
}
@media screen and (max-width: 516px) {
  .contactUs {
    background-attachment: unset;
  }
}

@media screen and (max-width: 465px) {
  .contactUs__rightLocation {
    min-width: 300px;
  }
  .contactUs__rightLocation > h1 {
    font-size: 2rem;
  }
  .contactUs__rightMap {
    order: 2;
    min-width: 300px;
  }
}

.contactForm {
	display: flex;
	flex-direction: column;
	width: 300px;
	padding: 10px;
}

.contactForm > input {
	border: none;
	border-bottom: 1px solid gray;
	padding: 10px;
	margin: 5px;
}

.contactForm > textarea {
	border: none;
	padding: 10px;
	resize: none;
	margin: 5px;
	margin-bottom: 10px;
	border-bottom: 1px solid gray;
	/* outline: none; */
}

.contactForm > button {
	background-color: black;
	color: white;
	border: none;
	outline: none;
	padding: 8px 0;
	border-radius: 3px;
	cursor: pointer;
}

.contactForm__number::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.contactForm__number {
	-moz-appearance: textfield;
}

.footer {
  background-color: rgb(51, 51, 51);
  padding: 20px 0 5px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hidden {
  /* visibility: hidden; */
  display: none;
}

.footer__left {
  flex: 0.3 1;
  display: flex;
}
.footer__left > img {
  object-fit: contain;
  flex: 1 1;
  height: 50px;
}
.footer__middle {
  flex: 0.4 1;
  text-align: center;
  color: rgb(173, 173, 173);
}
.footer__right {
  flex: 0.3 1;
  display: flex;
}

.footer__right > img {
  object-fit: contain;
  flex: 0.33 1;
  height: 50px;
}

@media screen and (max-width: 820px) {
  .footer__left {
    display: none;
  }
  .footer__right {
    display: none;
  }
  .footer__middle {
    flex: 1 1;
  }
}

@media screen and (max-width: 440px) {
  .footer {
    padding: 10px 0 0px 0;
  }
  .footer__middle > p {
    font-size: 0.7rem;
  }
}

.header__container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: white;
  /* height: 16vh; */

  padding: 10px;
}
.header {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

/* burger menu  */
.toggle__button {
  position: absolute;
  top: 2.4rem;
  right: 1.3rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

.toggle__button .bar {
  height: 3px;
  width: 100%;
  background-color: rgb(61, 61, 61);
  border-radius: 10px;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__left--image {
  cursor: pointer;
  width: 100%;
  max-width: 200px;
}

.header__nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__nav > p {
  font-size: 14px;
  padding: 10px;
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #4c4c4c;
  cursor: pointer;
  transition: transform 300ms ease;
  margin-top: 15px;
}
.header__nav > p:hover {
  color: rgb(210, 84, 89);
  color: #d5535a;
  transform: scale(1.1);
}

.header__nav > p > a {
  color: #4c4c4c !important;
}
.header__nav > p > a:hover {
  text-decoration: none !important;
  color: #d5535a !important;
  transform: scale(1.1);
}

.header__nav > a > button {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  padding: 8px 15px;
  border: none;
  outline: none !important;

  color: #d5535a;
  background-color: white;
  border: 2px solid rgb(210, 84, 89);
  margin-left: 5px;
}

.header__nav > a > button:hover {
  background-color: rgb(210, 84, 89);
  color: white;
}

.headerButtonLink:hover {
  text-decoration: none;
  color: rgb(210, 84, 89);
}

@media screen and (max-width: 1159px) {
  .header {
    width: 95%;
  }
}

@media (max-width: 1072px) {
  .header__nav > p {
    margin-right: 5px;
    font-size: 0.8rem;
  }
}

@media (max-width: 1023px) {
  .toggle__button {
    display: flex;
    position: static;
  }
  .activeToggle {
    display: none;
  }

  .header {
    align-items: center;
  }

  .header__nav {
    display: none;
  }

  .header__container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 615px) {
  .toggle__button {
    width: 25px;
    height: 18px;
  }

  .header__left--image {
    max-width: 140px;
  }
}

@media screen and (max-width: 460px) {
  .toggle__button {
    width: 23px;
    height: 17px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
}

.sidebarSlide {
	position: absolute;
	height: 100vh;
	background-color: rgb(247, 244, 244);
	width: 100%;
	transform: translateX(-100%);
	left: 0;
	top: 0;
	transition: 0.4s;

	/* padding: 20px; */

	display: flex;

	background-color: transparent;
}

.active {
	transform: translateX(0);
	transition: 0.7s;
	animation: fadeIn ease 1s;
}

.sidebarSlide__side {
	position: absolute;
	width: 10%;
	height: 100%;
	background-color: black;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0.7;
	padding: 10px;
	/* transition: 0.4s; */
	/* transform: translateX(100%); */
}

.sidebarSlide__left {
	width: 90%;
	height: 100%;
	background-color: white;
	padding: 15px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.sidebarSlide__side {
	display: flex;
	justify-content: center;
}
.sidebarSlide__side > .MuiSvgIcon-root {
	cursor: pointer;
	color: rgb(168, 11, 11);
	z-index: 2;
	margin-top: 12px;
}
.sidebarSlide__nav {
	padding-top: 10px;
	flex: 1 1;
}
.sidebarSlide__nav > img {
	width: 40px;
	padding-left: 5px;
}
.sidebarSlide__nav > p {
	padding: 5px;
	margin-top: 10px;
	color: #4c4c4c !important;
	cursor: pointer;
	font-weight: 500;
}
.sidebarSlide__nav > p > a {
	color: #4c4c4c;
}
.sidebarSlide__bottom {
	margin-bottom: 30px;
}
.bottom__moveUp {
	margin-bottom: 0;
}
.sidebarSlide__bottom--logos > img {
	width: 30px;
}
.sidebarSlide__bottom > p {
	font-size: 0.7rem;
	padding: 5px 0;
	color: rgb(150, 150, 150);
}
.socmed {
	text-decoration: none !important;
}
.contact {
	color: #d25459 !important;
	text-decoration: none !important;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.projects {
	height: 100vh;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	background-color: rgb(240, 240, 240);
}
.projects__title {
	/* flex: 0.25; */
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 20px;

	width: 30%;
}

.projects__title > h1 {
	margin-bottom: 10px;
	color: rgb(210, 84, 89);
	z-index: 2;
}

.projects__title > h1 > span {
	color: #4c4c4c;
	font-weight: 300 !important;
}

.projects__title > p {
	line-height: 0.4;
}

.projects__title > a > button {
	background-color: black;
	color: white;
	border: none;
	outline: none;
	padding: 8px;
	border-radius: 3px;
	cursor: pointer;
	font-size: 0.7rem;
	margin: 20px 0;
	letter-spacing: 0.7px;
}
.projects__carousel {
	/* flex: 0.75;
	margin-top: 60px; */

	width: 65%;
	display: flex;
	align-items: center !important;
	justify-content: center;
	opacity: 0;
}

.visible {
	opacity: 1;
	transition: 0.8s ease-in;
}

.alice-carousel img {
	width: 100%;
	border-radius: 10px 10px 0 0;
}

.alice-carousel {
	border: 1px solid lightgray;
	width: 100% !important;

	padding-bottom: 40px;
	position: relative;

	border-radius: 10px;
	box-shadow: 0px 5px 17px -7px rgba(0, 0, 0, 0.75);
	background-color: white;
}

.alice-carousel__dots {
	margin: 30px 3px 5px;
	padding: 0;
	list-style: none;
	text-align: center;

	position: absolute;
	bottom: 10px;

	left: 0;
	right: 0;
}

.alice-carousel__dots-item {
	display: inline-block;
	width: 30px !important;
	height: 3px !important;
	cursor: pointer;
	border-radius: 0 !important;
	background-color: #e0e4fb;

	background-color: #d25459 !important;
	background-color: black !important;
	opacity: 0.5;

	margin-left: 3px !important;
	margin-right: 3px !important;
}

.__active {
	background-color: black !important;
	opacity: 1;
}

@media screen and (max-width: 1110px) {
	.projects__title > h1 {
		font-size: 1.8rem;
	}
	.projects__title > p {
		font-size: 0.8rem;
	}
	.projects__title > a > button {
		font-size: 0.6rem;
	}
}

@media screen and (max-width: 1023px) {
	.projects {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.projects__carousel {
		width: 80%;
	}
	.projects__title {
		margin-top: 60px;
		margin-bottom: 20px;
	}
	.projects__title > a > button {
		margin-bottom: 0;
		display: none;
	}
}

@media screen and (max-width: 911px) {
	.projects__carousel {
		margin-bottom: 50px;
	}
	.projects__title {
		width: 100%;
	}
}
@media screen and (max-width: 700px) {
	.projects__carousel {
		margin-bottom: 120px;
	}
}
@media screen and (max-width: 600px) {
	.alice-carousel__dots-item {
		width: 20px !important;
	}
	.projects__carousel {
		width: 90%;
	}
	.projects__carousel {
		margin-bottom: 150px;
	}
}

@media screen and (max-width: 450px) {
	.projects__carousel {
		width: 95%;
	}
	.alice-carousel__dots-item {
		width: 15px !important;
	}
}

.services {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(240, 240, 240);
}

.services__text {
	padding: 30px;
	margin-bottom: 30px;
	margin-top: 100px;
}

.services__text > h1 {
	color: rgb(210, 84, 89);
}

.services__text > h1 > span {
	font-weight: 300;
	color: #4c4c4c;
}
.services__text > p {
	font-size: 1.15em;
	font-weight: 300;
	text-align: center;
	padding: 5px;
}

.services__grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

@media screen and (max-width: 1003px) {
	.services {
		height: auto;
	}
	.services__text {
		margin-top: 50px;
	}
	.services__grid {
		flex-direction: column;
	}
}

@media screen and (max-width: 601px) {
	.services__text {
		margin-bottom: 5px;
	}
}

@media screen and (max-width: 400px) {
	.services__text > h1 {
		font-size: 2.1rem;
	}
	.services__text > p {
		font-size: 1rem;
	}
}

.serviceCard {
	flex: 0.2 1;
	border-radius: 10px;
	overflow: hidden;
}
.serviceCard__image {
	border-radius: 10px;
	width: 100%;
	overflow: hidden;
}
.serviceCard__image > img {
	object-fit: contain;
	width: 100%;
	transition: transform 700ms ease-in-out;
}
.serviceCard__image > img:hover {
	transform: scale(1.07);
}

.serviceCard__text > h2 {
	padding: 10px;
	font-weight: 400;
	color: #4c4c4c;
	font-size: 1.8rem;
}

.serviceCard__text > ul {
	list-style-type: none;
	padding: 5px 20px;
	color: #4c4c4c;
}

.serviceCard__text > ul li::before {
	content: "\2022";
	color: rgb(210, 84, 89);
	font-weight: bold;
	display: inline-block;
	width: 1em;
	margin-left: -1em;
}

@media screen and (max-width: 1348px) {
	.serviceCard__text > h2 {
		font-size: 1.7rem;
	}
}
@media screen and (max-width: 1279px) {
	.serviceCard__text > h2 {
		font-size: 1.65rem;
	}
}
@media screen and (max-width: 1244px) {
	.serviceCard__text > h2 {
		font-size: 1.6rem;
	}
}

@media screen and (max-width: 1210px) {
	.serviceCard__text > h2 {
		font-size: 1.55rem;
	}
}

@media screen and (max-width: 1193px) {
	.serviceCard__text > h2 {
		font-size: 1.5rem;
	}
}
@media screen and (max-width: 1159px) {
	.serviceCard__text > h2 {
		font-size: 1.45rem;
	}
}

@media screen and (max-width: 1123px) {
	.serviceCard__text > h2 {
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 1089px) {
	.serviceCard__text > h2 {
		font-size: 1.35rem;
	}
}

@media screen and (max-width: 1053px) {
	.serviceCard__text > h2 {
		font-size: 1.3rem;
	}
}
@media screen and (max-width: 1020px) {
	.serviceCard__text > h2 {
		font-size: 1.2rem;
	}
}
@media screen and (max-width: 1003px) {
	.serviceCard__text {
		padding: 10px;
	}
	.serviceCard__text > h2 {
		font-size: 2rem;
		margin-top: 10px;
	}
	.serviceCard {
		margin-bottom: 50px;
	}
}

@media screen and (max-width: 601px) {
	.serviceCard {
		width: 85%;
		margin-left: auto;
		margin-right: auto;
	}
	.serviceCard__text > h2 {
		font-size: 1.7rem;
		margin-top: 10px;
		padding: 5px;
	}
	.serviceCard__text {
		padding: 0px;
		text-align: center;
	}
}

.slogan {
  background: url(/static/media/balconies.d64b4620.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-attachment: fixed;
  background-position: left top;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* default mobile zoom out  */
  /* 980px for iphone 800px for android  */
  /* min-width: 980px; */

  /* remove bouncing when scrolled  */
  /* overscroll-behavior:  ; */
  opacity: 0 !important;
}
.visible {
  opacity: 1 !important;
  transition: 800ms ease-in;
}
.slogan__overlay {
  background-color: lightgray;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
  opacity: 0.4;
  height: 100vh;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.slogan__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slogan__text > h1 {
  font-weight: 600;
  font-size: 4rem;
  color: white;
  opacity: 0.9;
  text-transform: uppercase;
  padding: 5px;
  z-index: 99;

  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.slogan__text > hr {
  width: 4.5rem;
  background-color: rgb(210, 84, 89);
  height: 3px;
  border: none;
  box-shadow: 1px 1px 2px #000000;
  z-index: 99;
  margin: 3px 0;
}

.slogan__text > a > button {
  font-weight: 600;
  background-color: rgb(210, 84, 89);
  color: white;
  margin: 15px 10px;
  padding: 10px 30px;
  box-shadow: 1px 1px 3px #000000;
  outline: none !important;
}

.slogan__text > a > button:hover {
  color: rgb(210, 84, 89);
  background-color: #fff;
  border: 2px solid rgb(210, 84, 89);
}
.button:hover {
  text-decoration: none;
}

@media screen and (max-width: 516px) {
  .slogan {
    background: #7dc8f7;
  }
}

@media screen and (max-width: 460px) {
  .slogan__text > h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 370px) {
  .slogan__text > h1 {
    font-size: 2.6rem;
  }
}

.teamPage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background: url(/static/media/blueprint.ab256de7.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.teamPage::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;

  background-color: rgb(36, 35, 35);
}

.teamPage__grid {
  z-index: 100;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  z-index: 2;
}

.teamPage__text {
  background-color: white;
  text-align: center;
  width: 40%;
  margin-bottom: 60px;
  margin-top: 50px;
  padding: 20px;
  z-index: 2;

  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
}

.teamPage__text > h1 {
  color: rgb(210, 84, 89);
}
.teamPage__text > h1 > span {
  color: #4c4c4c;
  font-weight: 300 !important;
}
.teamPage__text > p {
  font-size: 1.2rem;
  font-weight: 300;
}

@media screen and (max-width: 1065px) {
  .teamPage__text {
    padding: 10px;
  }
  .teamPage__text > h1 {
    font-size: 2rem;
    padding-top: 5px;
  }
}

@media screen and (max-width: 968px) {
  .teamPage {
    height: auto;
    padding-bottom: 50px;
  }
  .teamPage__text {
    margin-top: 100px;
  }
  .teamPage__grid {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 651px) {
  .teamPage__text > h1 {
    font-size: 1.7rem;
    padding-top: 8px;
  }
}
@media screen and (max-width: 584px) {
  .teamPage__text {
    width: 55%;
    font-size: 1.7rem;
  }
  .teamPage__grid {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 516px) {
  .teamPage {
    /* background: red; */
    background: url(/static/media/site.a32efa97.jpg);
    background-attachment: unset;
  }
}

@media screen and (max-width: 430px) {
  .teamPage__text {
    width: 70%;
    padding: 0px;
    padding-top: 5px;
  }
}

@media screen and (max-width: 360px) {
  .teamPage__text > h1 {
    font-size: 1.6rem;
    padding-top: 8px;
  }
}

.card {
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 10px;
	width: 20%;
	border-radius: 10px;

	box-shadow: 0px 5px 17px -7px rgba(0, 0, 0, 0.75) !important;
}

.card__image {
	object-fit: contain;
	padding: 10px;
}

.card__name {
	padding: 5px;
	font-weight: 400;
	color: #4c4c4c;
	flex-wrap: nowrap;
	font-size: 1.4rem;
}
.card__title {
	padding: 5px;
	color: gray;
	font-size: 1rem;
}

@media screen and (max-width: 1374px) {
	.card__name {
		font-size: 1.3rem;
	}
}
@media screen and (max-width: 1289px) {
	.card__name {
		font-size: 1.2rem;
	}
	.card__image {
		width: 100%;
	}
}

@media screen and (max-width: 1289px) {
	.card__image {
		width: 90%;
	}
	.card__name {
		font-size: 1.2rem;
		font-weight: 400;
		padding: 0;
	}
	.card__title {
		font-size: 0.8rem;
	}
}

@media screen and (max-width: 1151px) {
	.card__name {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 1065px) {
	.card {
		width: 22%;
	}
}
@media screen and (max-width: 968px) {
	.card {
		width: 90%;
	}
	.card__name {
		font-size: 1.4rem;
	}
	.card__title {
		font-size: 0.9rem;
	}
}
@media screen and (max-width: 620px) {
	.card {
		width: 90%;
	}
	.card__name {
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 584px) {
	.card__image {
		width: 70%;
	}
	.card {
		width: 70%;
	}
	.card__name {
		font-size: 1.6rem;
	}
	.card__title {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 430px) {
	.card__image {
		width: 70%;
	}
	.card {
		width: 85%;
	}
}
@media screen and (max-width: 360px) {
	.card__name {
		font-size: 1.3rem;
	}
}

.forms {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 240);
}
.forms__title {
  padding: 10px 10px 0 10px;
  border-radius: 10px;
  text-align: center;
}

.forms__title > h1 {
  padding: 0 10px;
  color: rgb(210, 84, 89);
  font-size: 2rem;
}
.forms__title > h1 > span {
  color: #4c4c4c;
  font-weight: 300 !important;
}

.forms__title > p {
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
}
.forms__title > h5 {
  font-weight: 400;
  line-height: 1;
  border-bottom: 1px solid #000;
}
.forms__options {
  display: flex;
  justify-content: center;
  opacity: 0;
}
.visible {
  opacity: 1;
  transition: 0.8s ease-in;
}

.forms__card {
  width: 50%;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 500ms ease-out;
  box-shadow: 0px 5px 17px -7px rgba(0, 0, 0, 0.75);
}
.forms__card:hover {
  transform: scale(1.09);
}
.forms__image {
  object-fit: contain;
  width: 100%;
  height: 250px;
  overflow: hidden;
  padding: 15px 0;
}

h3 {
  color: #4c4c4c;
  padding-bottom: 10px;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 770px) {
  h3 {
    font-size: 1.5rem !important;
  }
}

@media screen and (max-width: 659px) {
  h3 {
    font-size: 1.4rem !important;
  }
}

@media screen and (max-width: 611px) {
  h3 {
    font-size: 1.3rem !important;
  }
}

@media screen and (max-width: 611px) {
  h3 {
    font-size: 1.1rem !important;
  }
}

@media screen and (max-width: 588px) {
  .forms {
    height: auto;
    padding: 50px 0;
  }
  .forms__title {
    margin-top: 8vh !important;
  }
  .forms__options {
    flex-direction: column;
    align-items: center;
  }
  .forms__card {
    width: 70%;
  }
  .forms__title > h1 {
    font-size: 1.7rem;
  }
  .forms__title > p {
    font-size: 1.1rem;
  }
  .forms__title > h5 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 426px) {
  .forms__title {
    margin-top: 5vh !important;
  }
  .forms__title > h1 {
    font-size: 1.6rem;
  }
  .forms__title > p {
    font-size: 1rem;
  }
  .forms__title > h5 {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 384px) {
  .forms__card {
    width: 79%;
  }
  .forms__title > h1 {
    font-size: 1.4rem;
  }
  .forms__title > p {
    font-size: 0.9rem;
  }
  .forms__title > h5 {
    font-size: 0.7rem;
  }
}

.condoForm {
	padding: 50px;
	margin-top: 11vh;
	width: 71%;
	margin-left: auto;
	margin-right: auto;
}

.card-header {
	padding: 20px !important;
	display: flex;
}
.sv_header__text {
	align-self: center;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
}

.panel-body > div > h4 {
	color: red !important;
	font-weight: 400;
	font-size: 16px;
	padding: 13px;
	margin-left: 2px;
}

.sv_q_imgsel_text {
	color: black;
}

.panel-footer {
	text-align: right;
	padding: 20px !important;
}
.sv_complete_btn {
	background-color: black !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	padding-bottom: 3px !important;
	padding-top: 3px !important;
	text-transform: uppercase !important;
	border-radius: 3px !important;
}

@media screen and (max-width: 1046px) {
	.sv_header__text > h3 {
		font-size: 1.5rem !important;
	}
}
@media screen and (max-width: 975px) {
	.sv_header__text > h3 {
		font-size: 1.4rem !important;
	}
}

@media screen and (max-width: 948px) {
	.sv_header__text > h3 {
		font-size: 1.35rem !important;
	}
}

@media screen and (max-width: 931px) {
	.sv_header__text > h3 {
		font-size: 1.3rem !important;
	}
	.sv_logo__image {
		width: 80px;
	}
}

@media screen and (max-width: 764px) {
	.sv_qstn > div > h5 {
		font-size: 1rem;
	}
}

@media screen and (max-width: 743px) {
	.sv_header__text {
		min-width: 270px !important;
	}
	.condoForm {
		width: 100%;
	}
}

@media screen and (max-width: 548px) {
	.sv_header__text > h3 {
		font-size: 1.1rem !important;
	}
	.sv_header__text > h5 {
		font-size: 1rem !important;
	}
	.sv_logo__image {
		width: 50px;
	}
}

@media screen and (max-width: 461px) {
	.sv_header__text > h3 {
		font-size: 1rem !important;
	}
	.sv_header__text > h5 {
		font-size: 0.9rem !important;
	}
	.sv_logo__image {
		width: 40px;
	}
}

@media screen and (max-width: 461px) {
	.condoForm {
		padding: 10px;
		margin-top: 100px;
	}
}

@media screen and (max-width: 335px) {
	.condoForm {
		padding: 5px;
	}
	.sv_header__text {
		padding-right: 10px !important;
	}
}

.houseForm {
  padding: 50px;
  margin-top: 11vh;
  width: 71%;
  margin-left: auto;
  margin-right: auto;

  height: 100%;
}

.card-header {
  padding: 20px !important;
  display: flex;
}
.sv_header__text {
  align-self: center;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
}

.panel-body > div > h4 {
  color: red !important;
  font-weight: 400;
  font-size: 16px;
  padding: 13px;
  margin-left: 2px;
}

.sv_q_imgsel_text {
  color: black;
}

.panel-footer {
  text-align: right;
  padding: 20px !important;
}
.sv_complete_btn {
  background-color: black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding-bottom: 3px !important;
  padding-top: 3px !important;
  text-transform: uppercase !important;
  border-radius: 3px !important;
}

@media screen and (max-width: 1046px) {
  .sv_header__text > h3 {
    font-size: 1.5rem !important;
  }
}
@media screen and (max-width: 975px) {
  .sv_header__text > h3 {
    font-size: 1.4rem !important;
  }
}

@media screen and (max-width: 948px) {
  .sv_header__text > h3 {
    font-size: 1.35rem !important;
  }
}

@media screen and (max-width: 931px) {
  .sv_header__text > h3 {
    font-size: 1.3rem !important;
  }
  .sv_logo__image {
    width: 80px;
  }
}

@media screen and (max-width: 764px) {
  .sv_qstn > div > h5 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 743px) {
  .sv_header__text {
    min-width: 270px !important;
  }
  .houseForm {
    width: 100%;
  }
}

@media screen and (max-width: 548px) {
  .sv_header__text > h3 {
    font-size: 1.1rem !important;
  }
  .sv_header__text > h5 {
    font-size: 1rem !important;
  }
  .sv_logo__image {
    width: 50px;
  }
}

@media screen and (max-width: 461px) {
  .sv_header__text > h3 {
    font-size: 1rem !important;
  }
  .sv_header__text > h5 {
    font-size: 0.9rem !important;
  }
  .sv_logo__image {
    width: 40px;
  }
}

@media screen and (max-width: 461px) {
  .houseForm {
    padding: 10px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 335px) {
  .houseForm {
    padding: 5px;
  }
  .sv_header__text {
    padding-right: 10px !important;
  }
}

