.forms {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(240, 240, 240);
}
.forms__title {
  padding: 10px 10px 0 10px;
  border-radius: 10px;
  text-align: center;
}

.forms__title > h1 {
  padding: 0 10px;
  color: rgb(210, 84, 89);
  font-size: 2rem;
}
.forms__title > h1 > span {
  color: #4c4c4c;
  font-weight: 300 !important;
}

.forms__title > p {
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;
  margin-bottom: 30px;
}
.forms__title > h5 {
  font-weight: 400;
  line-height: 1;
  border-bottom: 1px solid #000;
}
.forms__options {
  display: flex;
  justify-content: center;
  opacity: 0;
}
.visible {
  opacity: 1;
  transition: 0.8s ease-in;
}

.forms__card {
  width: 50%;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin: 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 500ms ease-out;
  box-shadow: 0px 5px 17px -7px rgba(0, 0, 0, 0.75);
}
.forms__card:hover {
  transform: scale(1.09);
}
.forms__image {
  object-fit: contain;
  width: 100%;
  height: 250px;
  overflow: hidden;
  padding: 15px 0;
}

h3 {
  color: #4c4c4c;
  padding-bottom: 10px;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 770px) {
  h3 {
    font-size: 1.5rem !important;
  }
}

@media screen and (max-width: 659px) {
  h3 {
    font-size: 1.4rem !important;
  }
}

@media screen and (max-width: 611px) {
  h3 {
    font-size: 1.3rem !important;
  }
}

@media screen and (max-width: 611px) {
  h3 {
    font-size: 1.1rem !important;
  }
}

@media screen and (max-width: 588px) {
  .forms {
    height: auto;
    padding: 50px 0;
  }
  .forms__title {
    margin-top: 8vh !important;
  }
  .forms__options {
    flex-direction: column;
    align-items: center;
  }
  .forms__card {
    width: 70%;
  }
  .forms__title > h1 {
    font-size: 1.7rem;
  }
  .forms__title > p {
    font-size: 1.1rem;
  }
  .forms__title > h5 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 426px) {
  .forms__title {
    margin-top: 5vh !important;
  }
  .forms__title > h1 {
    font-size: 1.6rem;
  }
  .forms__title > p {
    font-size: 1rem;
  }
  .forms__title > h5 {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 384px) {
  .forms__card {
    width: 79%;
  }
  .forms__title > h1 {
    font-size: 1.4rem;
  }
  .forms__title > p {
    font-size: 0.9rem;
  }
  .forms__title > h5 {
    font-size: 0.7rem;
  }
}
