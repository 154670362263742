.services {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgb(240, 240, 240);
}

.services__text {
	padding: 30px;
	margin-bottom: 30px;
	margin-top: 100px;
}

.services__text > h1 {
	color: rgb(210, 84, 89);
}

.services__text > h1 > span {
	font-weight: 300;
	color: #4c4c4c;
}
.services__text > p {
	font-size: 1.15em;
	font-weight: 300;
	text-align: center;
	padding: 5px;
}

.services__grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

@media screen and (max-width: 1003px) {
	.services {
		height: auto;
	}
	.services__text {
		margin-top: 50px;
	}
	.services__grid {
		flex-direction: column;
	}
}

@media screen and (max-width: 601px) {
	.services__text {
		margin-bottom: 5px;
	}
}

@media screen and (max-width: 400px) {
	.services__text > h1 {
		font-size: 2.1rem;
	}
	.services__text > p {
		font-size: 1rem;
	}
}
