.slogan {
  background: url(../assets/images/balconies.jpg);
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-attachment: fixed;
  background-position: left top;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* default mobile zoom out  */
  /* 980px for iphone 800px for android  */
  /* min-width: 980px; */

  /* remove bouncing when scrolled  */
  /* overscroll-behavior:  ; */
  opacity: 0 !important;
}
.visible {
  opacity: 1 !important;
  transition: 800ms ease-in;
}
.slogan__overlay {
  background-color: lightgray;
  filter: brightness(50%);
  opacity: 0.4;
  height: 100vh;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.slogan__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slogan__text > h1 {
  font-weight: 600;
  font-size: 4rem;
  color: white;
  opacity: 0.9;
  text-transform: uppercase;
  padding: 5px;
  z-index: 99;

  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.slogan__text > hr {
  width: 4.5rem;
  background-color: rgb(210, 84, 89);
  height: 3px;
  border: none;
  box-shadow: 1px 1px 2px #000000;
  z-index: 99;
  margin: 3px 0;
}

.slogan__text > a > button {
  font-weight: 600;
  background-color: rgb(210, 84, 89);
  color: white;
  margin: 15px 10px;
  padding: 10px 30px;
  box-shadow: 1px 1px 3px #000000;
  outline: none !important;
}

.slogan__text > a > button:hover {
  color: rgb(210, 84, 89);
  background-color: #fff;
  border: 2px solid rgb(210, 84, 89);
}
.button:hover {
  text-decoration: none;
}

@media screen and (max-width: 516px) {
  .slogan {
    background: #7dc8f7;
  }
}

@media screen and (max-width: 460px) {
  .slogan__text > h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 370px) {
  .slogan__text > h1 {
    font-size: 2.6rem;
  }
}
