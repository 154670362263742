.teamPage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background: url(../assets/images/blueprint.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.teamPage::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;

  background-color: rgb(36, 35, 35);
}

.teamPage__grid {
  z-index: 100;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  z-index: 2;
}

.teamPage__text {
  background-color: white;
  text-align: center;
  width: 40%;
  margin-bottom: 60px;
  margin-top: 50px;
  padding: 20px;
  z-index: 2;

  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
}

.teamPage__text > h1 {
  color: rgb(210, 84, 89);
}
.teamPage__text > h1 > span {
  color: #4c4c4c;
  font-weight: 300 !important;
}
.teamPage__text > p {
  font-size: 1.2rem;
  font-weight: 300;
}

@media screen and (max-width: 1065px) {
  .teamPage__text {
    padding: 10px;
  }
  .teamPage__text > h1 {
    font-size: 2rem;
    padding-top: 5px;
  }
}

@media screen and (max-width: 968px) {
  .teamPage {
    height: auto;
    padding-bottom: 50px;
  }
  .teamPage__text {
    margin-top: 100px;
  }
  .teamPage__grid {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 651px) {
  .teamPage__text > h1 {
    font-size: 1.7rem;
    padding-top: 8px;
  }
}
@media screen and (max-width: 584px) {
  .teamPage__text {
    width: 55%;
    font-size: 1.7rem;
  }
  .teamPage__grid {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 516px) {
  .teamPage {
    /* background: red; */
    background: url(../assets/images/site.jpg);
    background-attachment: unset;
  }
}

@media screen and (max-width: 430px) {
  .teamPage__text {
    width: 70%;
    padding: 0px;
    padding-top: 5px;
  }
}

@media screen and (max-width: 360px) {
  .teamPage__text > h1 {
    font-size: 1.6rem;
    padding-top: 8px;
  }
}
