/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Roboto:wght@300;400;500;700&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap"); */

* {
	margin: 0;
	padding: 0;
}
html,
body {
	width: 100%;
	height: 100%;
}
.app {
	width: 100%;
}
