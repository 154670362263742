.contactForm {
	display: flex;
	flex-direction: column;
	width: 300px;
	padding: 10px;
}

.contactForm > input {
	border: none;
	border-bottom: 1px solid gray;
	padding: 10px;
	margin: 5px;
}

.contactForm > textarea {
	border: none;
	padding: 10px;
	resize: none;
	margin: 5px;
	margin-bottom: 10px;
	border-bottom: 1px solid gray;
	/* outline: none; */
}

.contactForm > button {
	background-color: black;
	color: white;
	border: none;
	outline: none;
	padding: 8px 0;
	border-radius: 3px;
	cursor: pointer;
}

.contactForm__number::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.contactForm__number {
	-moz-appearance: textfield;
}
