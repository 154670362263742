.header__container {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: white;
  /* height: 16vh; */

  padding: 10px;
}
.header {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

/* burger menu  */
.toggle__button {
  position: absolute;
  top: 2.4rem;
  right: 1.3rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

.toggle__button .bar {
  height: 3px;
  width: 100%;
  background-color: rgb(61, 61, 61);
  border-radius: 10px;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__left--image {
  cursor: pointer;
  width: 100%;
  max-width: 200px;
}

.header__nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__nav > p {
  font-size: 14px;
  padding: 10px;
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #4c4c4c;
  cursor: pointer;
  transition: transform 300ms ease;
  margin-top: 15px;
}
.header__nav > p:hover {
  color: rgb(210, 84, 89);
  color: #d5535a;
  transform: scale(1.1);
}

.header__nav > p > a {
  color: #4c4c4c !important;
}
.header__nav > p > a:hover {
  text-decoration: none !important;
  color: #d5535a !important;
  transform: scale(1.1);
}

.header__nav > a > button {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  padding: 8px 15px;
  border: none;
  outline: none !important;

  color: #d5535a;
  background-color: white;
  border: 2px solid rgb(210, 84, 89);
  margin-left: 5px;
}

.header__nav > a > button:hover {
  background-color: rgb(210, 84, 89);
  color: white;
}

.headerButtonLink:hover {
  text-decoration: none;
  color: rgb(210, 84, 89);
}

@media screen and (max-width: 1159px) {
  .header {
    width: 95%;
  }
}

@media (max-width: 1072px) {
  .header__nav > p {
    margin-right: 5px;
    font-size: 0.8rem;
  }
}

@media (max-width: 1023px) {
  .toggle__button {
    display: flex;
    position: static;
  }
  .activeToggle {
    display: none;
  }

  .header {
    align-items: center;
  }

  .header__nav {
    display: none;
  }

  .header__container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 615px) {
  .toggle__button {
    width: 25px;
    height: 18px;
  }

  .header__left--image {
    max-width: 140px;
  }
}

@media screen and (max-width: 460px) {
  .toggle__button {
    width: 23px;
    height: 17px;
    margin-right: 5px;
    margin-bottom: 3px;
  }
}
