.projects {
	height: 100vh;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	background-color: rgb(240, 240, 240);
}
.projects__title {
	/* flex: 0.25; */
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 20px;

	width: 30%;
}

.projects__title > h1 {
	margin-bottom: 10px;
	color: rgb(210, 84, 89);
	z-index: 2;
}

.projects__title > h1 > span {
	color: #4c4c4c;
	font-weight: 300 !important;
}

.projects__title > p {
	line-height: 0.4;
}

.projects__title > a > button {
	background-color: black;
	color: white;
	border: none;
	outline: none;
	padding: 8px;
	border-radius: 3px;
	cursor: pointer;
	font-size: 0.7rem;
	margin: 20px 0;
	letter-spacing: 0.7px;
}
.projects__carousel {
	/* flex: 0.75;
	margin-top: 60px; */

	width: 65%;
	display: flex;
	align-items: center !important;
	justify-content: center;
	opacity: 0;
}

.visible {
	opacity: 1;
	transition: 0.8s ease-in;
}

.alice-carousel img {
	width: 100%;
	border-radius: 10px 10px 0 0;
}

.alice-carousel {
	border: 1px solid lightgray;
	width: 100% !important;

	padding-bottom: 40px;
	position: relative;

	border-radius: 10px;
	box-shadow: 0px 5px 17px -7px rgba(0, 0, 0, 0.75);
	background-color: white;
}

.alice-carousel__dots {
	margin: 30px 3px 5px;
	padding: 0;
	list-style: none;
	text-align: center;

	position: absolute;
	bottom: 10px;

	left: 0;
	right: 0;
}

.alice-carousel__dots-item {
	display: inline-block;
	width: 30px !important;
	height: 3px !important;
	cursor: pointer;
	border-radius: 0 !important;
	background-color: #e0e4fb;

	background-color: #d25459 !important;
	background-color: black !important;
	opacity: 0.5;

	margin-left: 3px !important;
	margin-right: 3px !important;
}

.__active {
	background-color: black !important;
	opacity: 1;
}

@media screen and (max-width: 1110px) {
	.projects__title > h1 {
		font-size: 1.8rem;
	}
	.projects__title > p {
		font-size: 0.8rem;
	}
	.projects__title > a > button {
		font-size: 0.6rem;
	}
}

@media screen and (max-width: 1023px) {
	.projects {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.projects__carousel {
		width: 80%;
	}
	.projects__title {
		margin-top: 60px;
		margin-bottom: 20px;
	}
	.projects__title > a > button {
		margin-bottom: 0;
		display: none;
	}
}

@media screen and (max-width: 911px) {
	.projects__carousel {
		margin-bottom: 50px;
	}
	.projects__title {
		width: 100%;
	}
}
@media screen and (max-width: 700px) {
	.projects__carousel {
		margin-bottom: 120px;
	}
}
@media screen and (max-width: 600px) {
	.alice-carousel__dots-item {
		width: 20px !important;
	}
	.projects__carousel {
		width: 90%;
	}
	.projects__carousel {
		margin-bottom: 150px;
	}
}

@media screen and (max-width: 450px) {
	.projects__carousel {
		width: 95%;
	}
	.alice-carousel__dots-item {
		width: 15px !important;
	}
}
