.footer {
  background-color: rgb(51, 51, 51);
  padding: 20px 0 5px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.hidden {
  /* visibility: hidden; */
  display: none;
}

.footer__left {
  flex: 0.3;
  display: flex;
}
.footer__left > img {
  object-fit: contain;
  flex: 1;
  height: 50px;
}
.footer__middle {
  flex: 0.4;
  text-align: center;
  color: rgb(173, 173, 173);
}
.footer__right {
  flex: 0.3;
  display: flex;
}

.footer__right > img {
  object-fit: contain;
  flex: 0.33;
  height: 50px;
}

@media screen and (max-width: 820px) {
  .footer__left {
    display: none;
  }
  .footer__right {
    display: none;
  }
  .footer__middle {
    flex: 1;
  }
}

@media screen and (max-width: 440px) {
  .footer {
    padding: 10px 0 0px 0;
  }
  .footer__middle > p {
    font-size: 0.7rem;
  }
}
